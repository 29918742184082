import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { useIntl } from 'react-intl';
import Table from '@/components/Table';
import MailStatusCounter from '../MailStatusCounter/MailStatusCounter';
import MailContent from '../MailContent';

import styles from './tableBulkEmails.module.scss';

const TableBulkEmails = ({
  data,
  ...restProps
}) => {
  const intl = useIntl();

  const columns = [
    {
      name: intl.formatMessage({ id: 'BULK_EMAIL.MODEL.SUBJECT' }),
      selector: 'subject',
      sortable: true,
    },
    {
      name: intl.formatMessage({ id: 'BULK_EMAIL.MODEL.SENDER' }),
      selector: 'sender',
      sortable: true,
    },
    {
      name: intl.formatMessage({ id: 'BULK_EMAIL.MODEL.RECIPIENT_NUMBER' }),
      sortable: false,
      cell: row => (
        <>
          <span className={styles.recipient_Number}>{row.amountOfRecipient}</span>
          <MailStatusCounter messageId={row.messageId} />
        </>
      ),
    },
    {
      name: intl.formatMessage({ id: 'BULK_EMAIL.MODEL.SENT_BY' }),
      selector: 'sendBy',
      sortable: true,
    },
    {
      name: intl.formatMessage({ id: 'BULK_EMAIL.MODEL.DATE' }),
      selector: 'sendDate',
      sortable: true,
      cell: row => <Moment format="DD.MM.YYYY. HH:mm">{row.sendDate}</Moment>,
    },
    {
      name: intl.formatMessage({ id: 'BULK_EMAIL.MODEL.CONTENT' }),
      selector: 'content',
      sortable: false,
      cell: row => <MailContent id={row.id} />,
    },
  ];

  return (
    <Table
      {...restProps}
      columns={columns}
      data={data}
    />
  );
};

TableBulkEmails.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default memo(TableBulkEmails);
