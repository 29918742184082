export const CHANGE_PASSWORD_FORM = 'CHANGE_PASSWORD_FORM';
export const ACCOUNT_FORM = 'ACCOUNT_FORM';
export const MEMBER_FORM = 'MEMBER_FORM';
export const MEMBER_FILTER_FORM = 'MEMBER_FILTER_FORM';
export const ADD_MEMBER_RULE_FORM = 'ADD_MEMBER_RULE_FORM';
export const PAYMENT_FILTER_FORM = 'PAYMENT_FILTER_FORM';
export const MEMBERSHIP_SETTING_FORM = 'MEMBERSHIP_SETTING_FORM';
export const BULK_EMAIL_FORM = 'BULK_EMAIL_FORM';
export const CREATE_PAYMENT_FORM = 'CREATE_PAYMENT_FORM';

export const BULK_EMAIL_FILTER_FORM = 'BULK_EMAIL_FILTER_FORM';

export default {
  CHANGE_PASSWORD_FORM,
  ACCOUNT_FORM,
  MEMBER_FORM,
  ADD_MEMBER_RULE_FORM,
  MEMBER_FILTER_FORM,
  PAYMENT_FILTER_FORM,
  MEMBERSHIP_SETTING_FORM,
  BULK_EMAIL_FORM,
  BULK_EMAIL_FILTER_FORM,
  CREATE_PAYMENT_FORM,
};
