const validate = values => {
  const requiredMessage = 'FORM_VALIDATION.FIELD_REQUIRED';

  const errors = {
    year: !values.year && requiredMessage,
    amount: !values.amount && requiredMessage,
    status: !values.status && requiredMessage,
  };

  return errors;
};

export default validate;
