export const ERROR_CODE_SCOPES = {
  LOCAL: 'LOCAL',
  IDENTITY: 'IDENTITY',
  MANAGEMENT: 'MANAGEMENT',
};

export const LOCAL_ERRORS = {
  10001: 'ERROR.FETCHING_TIMEOUT',
};

export const IDENTITY_ERRORS = {
  10021: 'ERROR.DOCUMENT.CANNOT_UPLOAD',
  10022: 'ERROR.DOCUMENT.CANNOT_DOWNLOAD',
  10023: 'ERROR.DOCUMENT.CANNOT_DELETE',

  10101: 'ERROR.CHANGE_PASSWORD.INVALID_REQUEST',
  10102: 'ERROR.CHANGE_PASSWORD.INVALID_CURRENT_PASSWORD',
  10103: 'ERROR.CHANGE_PASSWORD.INVALID_NEW_PASSWORD',
  10104: 'ERROR.CHANGE_PASSWORD.PASSWORD_CONFIRM_NOT_MATCH',
  10105: 'ERROR.CHANGE_PASSWORD.VIOLATE_PASSWORD_HISTORY_POLICY',

  10201: 'ERROR.USER_ACCOUNTS.INVALID_EMAIL',
  10202: 'ERROR.USER_ACCOUNTS.INVALID_NAME',
  10203: 'ERROR.USER_ACCOUNTS.INVALID_SURNAME',
  10204: 'ERROR.USER_ACCOUNTS.INVALID_ROLE',
  10205: 'ERROR.USER_ACCOUNTS.EMAIL_EXISTS',
  10206: 'ERROR.USER_ACCOUNTS.CANNOT_CREATE',
  10207: 'ERROR.USER_ACCOUNTS.INVALID_FULLNAME',
  10208: 'ERROR.USER_ACCOUNTS.FORBIDDEN_ROLE',

  10301: 'ERROR.RESET_PASSWORD.CANNOT_REMOVE_PASSWORD',
  10302: 'ERROR.RESET_PASSWORD.CANNOT_ADD_PASSWORD',

  10401: 'ERROR.UPDATE_USER.INVALID_EMAIL',
  10402: 'ERROR.UPDATE_USER.INVALID_NAME',
  10403: 'ERROR.UPDATE_USER.INVALID_SURNAME',
  10404: 'ERROR.UPDATE_USER.INVALID_ROLE',
  10405: 'ERROR.UPDATE_USER.CANNOT_CHANGE_EMAIL',
  10406: 'ERROR.UPDATE_USER.CANNOT_UPDATE',
  10408: 'ERROR.UPDATE_USER.FORBIDDEN_ROLE',
  10409: 'ERROR.UPDATE_USER.INVALID_USER_ID',
  10410: 'ERROR.UPDATE_USER.CANNOT_CHANGE_USERNAME',

  10501: 'ERROR.UPDATE_USER_ACTIVE_STATUS.INVALID_USER_ID',
  10502: 'ERROR.UPDATE_USER_ACTIVE_STATUS.CANNOT_UPDATE',

  10601: 'ERROR.DELETE_USER.INVALID_USER_ID',
  10602: 'ERROR.DELETE_USER.CANNOT_DELETE',
};

export const MANAGEMENT_ERRORS = {
  10001: 'ERROR.CREATE_MEMBERS.INVALID_NAME',
  10002: 'ERROR.CREATE_MEMBERS.INVALID_SURNAME',
  10003: 'ERROR.CREATE_MEMBERS.INVALID_SSN',
  10004: 'ERROR.CREATE_MEMBERS.INVALID_DATEOFBIRTH',
  10005: 'ERROR.CREATE_MEMBERS.INVALID_PLACEOFBIRTH',
  10006: 'ERROR.CREATE_MEMBERS.INVALID_EMAIL',
  10007: 'ERROR.CREATE_MEMBERS.INVALID_CONTACT_NUMBER',
  10008: 'ERROR.CREATE_MEMBERS.INVALID_COUNTRY',
  10009: 'ERROR.CREATE_MEMBERS.INVALID_ELECTION_UNIT',
  10010: 'ERROR.CREATE_MEMBERS.INVALID_COUNTY',
  10011: 'ERROR.CREATE_MEMBERS.INVALID_MUNICIPALITY',
  10012: 'ERROR.CREATE_MEMBERS.INVALID_SETTLEMENT',
  10013: 'ERROR.CREATE_MEMBERS.INVALID_STREET_NUMBER',
  10014: 'ERROR.CREATE_MEMBERS.INVALID_POSTAL_CODE',
  10015: 'ERROR.CREATE_MEMBERS.INVALID_PROFESSION',
  10016: 'ERROR.CREATE_MEMBERS.INVALID_SCHOOL',
  10017: 'ERROR.CREATE_MEMBERS.CANNOT_CREATE',

  10101: 'ERROR.UPDATE_MEMBERS.INVALID_NAME',
  10102: 'ERROR.UPDATE_MEMBERS.INVALID_SURNAME',
  10103: 'ERROR.UPDATE_MEMBERS.INVALID_SSN',
  10104: 'ERROR.UPDATE_MEMBERS.INVALID_DATEOFBIRTH',
  10105: 'ERROR.UPDATE_MEMBERS.INVALID_PLACEOFBIRTH',
  10106: 'ERROR.UPDATE_MEMBERS.INVALID_EMAIL',
  10107: 'ERROR.UPDATE_MEMBERS.INVALID_CONTACT_NUMBER',
  10108: 'ERROR.UPDATE_MEMBERS.INVALID_COUNTRY',
  10109: 'ERROR.UPDATE_MEMBERS.INVALID_ELECTION_UNIT',
  10110: 'ERROR.UPDATE_MEMBERS.INVALID_COUNTY',
  10111: 'ERROR.UPDATE_MEMBERS.INVALID_MUNICIPALITY',
  10112: 'ERROR.UPDATE_MEMBERS.INVALID_SETTLEMENT',
  10113: 'ERROR.UPDATE_MEMBERS.INVALID_STREET_NUMBER',
  10114: 'ERROR.UPDATE_MEMBERS.INVALID_POSTAL_CODE',
  10115: 'ERROR.UPDATE_MEMBERS.INVALID_PROFESSION',
  10116: 'ERROR.UPDATE_MEMBERS.INVALID_SCHOOL',
  10117: 'ERROR.UPDATE_MEMBERS.CANNOT_CREATE',
  10118: 'ERROR.UPDATE_MEMBERS.INVALID_ID',
  10119: 'ERROR.UPDATE_MEMBERS.INVALID_INVITED_UPDATE',

  10201: 'ERROR.DELETE_MEMBERS.INVALID_ID',

  11401: 'ERROR.IMPORT_PAYMENTS.UNRECOGNIZED_FILE_FORMAT',
  11402: 'ERROR.IMPORT_PAYMENTS.INVALID_CURRENCY',
  11403: 'ERROR.IMPORT_PAYMENTS.INVALID_DATE_RANGE',
  11404: 'ERROR.IMPORT_PAYMENTS.INVALID_REFERENCE_NUMBER',
  11405: 'ERROR.IMPORT_PAYMENTS.MEMBER_SSN_NOT_FOUND',
  11406: 'ERROR.IMPORT_PAYMENTS.INVALID_SSN_NUMBER',
  11501: 'ERROR.PAYMENT_TRANSACTION.INVALID_ID',
  11599: 'ERROR.PAYMENT_TRANSACTION.CANNOT_UPDATE',
};

export default {
  [ERROR_CODE_SCOPES.LOCAL]: LOCAL_ERRORS,
  [ERROR_CODE_SCOPES.IDENTITY]: IDENTITY_ERRORS,
  [ERROR_CODE_SCOPES.MANAGEMENT]: MANAGEMENT_ERRORS,
};
