import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import sanitizeHtml from 'sanitize-html';
import TooltipLink from '@/components/TooltipLink';
import { getContents, requestContents } from '@/redux/ducks/bulkMails.duck';
import { hasPermissions } from '@/utils/user';
import { PERMISSIONS } from '@/constants/userAccounts';
import styles from './mailContent.module.scss';

const borderColor = '#e0e0e0';

const MailContent = ({ id }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const contents = useSelector(getContents);

  const renderContent = () => {
    const content = contents[id];
    if (content) {
      if (content.isFetching) {
        return <div>{intl.formatMessage({ id: 'BULK_EMAIL.MAIL_ACTIVITY.LOADING' })}</div>;
      }
      return (
        // eslint-disable-next-line react/no-danger
        <div dangerouslySetInnerHTML={{
          __html: sanitizeHtml(content.data),
        }}
        />
      );
    }
    return null;
  };

  const handleHover = () => {
    const content = contents[id];
    if (hasPermissions([PERMISSIONS.BULK_EMAIL.value]) && (!content || !content.isFetching)) {
      dispatch(requestContents({ id }));
    }
  };

  return (
    <TooltipLink
      tooltipClassName={styles.contentTooltip}
      className={styles.content}
      type="light"
      place="bottom"
      border
      borderColor={borderColor}
      tooltip={renderContent()}
      onHover={handleHover}
    >
      <i className="fa fa-exclamation-circle" aria-hidden="true" />
    </TooltipLink>
  );
};

MailContent.propTypes = {
  id: PropTypes.string.isRequired,
};

export default memo(MailContent);
