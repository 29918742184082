import {
  takeLatest,
  all,
  put,
  call,
  takeEvery,
} from 'redux-saga/effects';
import {
  setFetching,
  requestSuccess,
  requestFailed as requestFailedAction,
  sendBulkMails,
  fetchBulkMails,
  fetchStatusMailSent,
  setFetchingStatusMailSent,
  requestStatusMailSentSuccess,
  requestStatusMailSentFailed,
  requestContents,
  requestContentsSuccess,
  requestContentsFailed,
  fetchTotalMailSent,
} from '@/redux/ducks/bulkMails.duck';
import { ERROR_CODE_SCOPES } from '@/constants/errorCodes';
import { api } from '@/services';
import { odataFilter } from '@/utils';

const requestFailed = args =>
  requestFailedAction({ ...args, scope: ERROR_CODE_SCOPES.MANAGEMENT });

function* onSendBulkMailsFlow() {
  yield takeLatest(sendBulkMails, function* onSendBulkMails({
    payload: { bulkMails, callback = () => {} },
  }) {
    yield put(setFetching());
    try {
      yield call(api.post, 'api/BulkMail', bulkMails);
      yield callback();
      yield put(
        requestSuccess(),
      );
    } catch (error) {
      yield put(requestFailed({ error }));
    }
  });
}

const generateBulkMailFilters = filters => {
  const filtersOdata = odataFilter.compile(filters, {
    dateFrom: odataFilter.types.ge,
    dateTo: odataFilter.types.le,
  }, {
    dateFrom: 'sendDate',
    dateTo: 'sendDate',
  });
  return filtersOdata;
};

function* onGetBulkMailsFlow() {
  yield takeLatest(fetchBulkMails, function* onGetBulkMails({
    payload: { page, rowPerPage, filters = {}, sortOrder = {} } = {},
  }) {
    yield put(setFetching());
    try {
      const filtersOdata = generateBulkMailFilters(filters);
      const data = yield call(api.get, 'api/BulkMail', {
        ...filtersOdata,
        $orderby: sortOrder.column
          ? `${sortOrder.column} ${sortOrder.sortDirection}`
          : 'sendDate desc',
        $skip: page && rowPerPage && (page - 1) * rowPerPage,
        $top: rowPerPage,
        $count: true,
      });
      yield put(
        requestSuccess({
          response: {
            data: data.value,
            total: data['@odata.count'],
          },
          dismiss: true,
        }),
      );
    } catch (error) {
      yield put(requestFailed({ error }));
    }
  });
}

function* onGetMailStatusList() {
  yield takeEvery(fetchStatusMailSent, function* onGetStatusMailSent({
    payload: { status, messageIds },
  }) {
    yield put(setFetchingStatusMailSent({
      [status]: {
        [messageIds.join('|')]: {
          fetching: true,
        },
      },
    }));
    try {
      const params = {
        status,
        messageIds,
      };
      const data = yield call(api.get, 'api/mailactivities', {
        ...params,
      });
      yield put(requestStatusMailSentSuccess({
        response: {
          [status]: {
            [messageIds.join('|')]: {
              ...data,
              fetching: false,
            },
          },
        },
      }));
    } catch (error) {
      yield put(requestStatusMailSentFailed({ error }));
    }
  });
}

function* onGetContentsFlow() {
  yield takeLatest(requestContents, function* onGetContents({
    payload: { id },
  }) {
    try {
      const data = yield call(api.get, `api/BulkMailDetails/${id}`);
      yield put(
        requestContentsSuccess({
          id: data.id,
          data: data.content,
        }),
      );
    } catch (error) {
      yield put(requestContentsFailed({ id, error }));
    }
  });
}

function* onGetTotalMailSentFlow() {
  yield takeLatest(fetchTotalMailSent, function* onGetTotalMailSent() {
    yield put(setFetching());
    try {
      const data = yield call(api.get, 'api/BulkMailDetails/Total');
      yield put(
        requestSuccess({
          response: { totalMailSent: data.grandTotal, totalMailSentInMonth: data.monthlyTotal },
          dismiss: true,
        }),
      );
    } catch (error) {
      yield put(requestFailed({ error }));
    }
  });
}

export default function* bulkMailsSaga() {
  yield all([
    onSendBulkMailsFlow(),
    onGetBulkMailsFlow(),
    onGetTotalMailSentFlow(),
    onGetMailStatusList(),
    onGetContentsFlow(),
  ]);
}
