import React from 'react';
import { compose } from 'redux';
import { useIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { CREATE_PAYMENT_FORM } from '@/constants/forms';
import { Input, Option, Select } from '@/components/Form';
import { PAYMENT_TRANSACTION_STATUSES } from '@/constants/member';
import validate from './validate';

import styles from './createPaymentForm.module.scss';

const CreatePaymentForm = () => {
  const intl = useIntl();

  return (
    <form autoComplete="off" className={styles.container}>
      <div className={styles.content}>
        <Field
          component={Input}
          type="number"
          name="year"
          className={styles.input}
          label={intl.formatMessage({ id: 'PAYMENTS.MODEL.YEAR' })}
          required
        />
        <Field
          component={Input}
          type="number"
          name="amount"
          className={styles.input}
          label={intl.formatMessage({ id: 'PAYMENTS.MODEL.AMOUNT' })}
          required
        />
        <Field
          component={Select}
          type="text"
          name="status"
          className={styles.input}
          label={intl.formatMessage({ id: 'PAYMENTS.MODEL.STATUS' })}
          required
        >
          <Option value="" hidden />
          {Object.keys(PAYMENT_TRANSACTION_STATUSES).map(key => (
            <Option
              key={key}
              value={PAYMENT_TRANSACTION_STATUSES[key].alias}
            >
              {intl.formatMessage({
                id: PAYMENT_TRANSACTION_STATUSES[key].displayName,
              })}
            </Option>
          ))}
        </Field>
      </div>
    </form>
  );
};

export default compose(
  reduxForm({
    form: CREATE_PAYMENT_FORM,
    validate,
    destroyOnUnmount: true,
  }),
)(CreatePaymentForm);
