import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { Form, reduxForm, getFormValues, submit } from 'redux-form';
import { CREATE_PAYMENT_FORM, MEMBER_FORM } from '@/constants/forms';
import CustomModal from '@/components/CustomModal';
import { createPaymentTransaction } from '@/redux/ducks/payments.duck';

import BasicInformation from './BasicInformation';
import ContactInformation from './ContactInformation';
import Address from './Address';
import Education from './Education';
import Organizations from './Organizations';
import Documents from './Documents';
import Invitation from './Invitation';
import MemberCard from './MemberCard';
import SystemInformation from './SystemInformation';
import validate from './validate';
import Payments from './Payments/Payments';
import CreatePaymentForm from './Payments/CreatePaymentForm';

const MemberForm = ({
  data,
  onSubmit,
  handleSubmit,
  documentsProps,
  hasModifyPermission,
  systemInfomationProps,
}) => {
  const formValues = useSelector(getFormValues(MEMBER_FORM));
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  const handleFormSubmit = formValues => {
    setLoading(true);
    dispatch(
      createPaymentTransaction({
        data: {
          memberId: data.id,
          ...formValues,
        },
        callback: () => {
          toggleModal(false);
          setLoading(false);
        },
      }),
    );
  };

  const handleModalOk = () => {
    dispatch(submit(CREATE_PAYMENT_FORM));
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <BasicInformation readOnly={!hasModifyPermission} />
        {
          !formValues.isPrivate && (
            <>
              <ContactInformation readOnly={!hasModifyPermission} />
              <Address readOnly={!hasModifyPermission} />
              <Education readOnly={!hasModifyPermission} />
              <Organizations readOnly={!hasModifyPermission} />
              {data.id && <Payments member={data} toggleModal={toggleModal} />}
              <Documents
                {...documentsProps}
                data={data}
                readOnly={!hasModifyPermission}
              />
              <Invitation readOnly={!hasModifyPermission} />
            </>
          )
        }
        <MemberCard readOnly={!hasModifyPermission} />
        <SystemInformation
          {...systemInfomationProps}
          data={data}
          readOnly={!hasModifyPermission}
        />
      </Form>
      <CustomModal
        toggle={toggleModal}
        open={openModal}
        okButtonProps={{ onClick: handleModalOk, loading }}
      >
        <CreatePaymentForm onSubmit={handleFormSubmit} />
      </CustomModal>
    </>
  );
};

MemberForm.defaultProps = {
  data: {},
  documentsProps: {},
  systemInfomationProps: {},
  hasModifyPermission: false,
};
MemberForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  data: PropTypes.object,
  documentsProps: PropTypes.object,
  hasModifyPermission: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  systemInfomationProps: PropTypes.object,
};

export default compose(
  connect((state, props) => ({
    initialValues: {
      ...props.data,
      electionUnit: (props.data && props.data.electionUnit) || 11,
      cardProvidedByMember: props.data && ({
        label: props.data.cardProvidedByMember,
        value: props.data.cardProvidedByMemberId,
      }),
    },
  })),
  reduxForm({
    form: MEMBER_FORM,
    validate,
    destroyOnUnmount: true,
  }),
)(MemberForm);
